import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IEncodedResult } from '../../models/shared/encoded-result';
import { IGiftKarticka } from '../../models/fiskalna-kasa/gift-karticka';

@Injectable()
export class GiftKartickiService {

    constructor(private http: HttpClient) {}

    getKarticka(sifra: string): Observable<IGiftKarticka> {
        return this.http.get<IGiftKarticka>(`/KasaApi/GiftKarticki/GetKarticka/${sifra}`);
    }

    prodadiKarticka(id: number, qr: string): Observable<IEncodedResult> {
        return this.http.get<IEncodedResult>(`/KasaApi/GiftKarticki/ProdadiKarticka/${id}/${qr}`);
    }

    stornirajKarticka(id: number, qr: string): Observable<IEncodedResult> {
        return this.http.get<IEncodedResult>(`/KasaApi/GiftKarticki/StornirajKarticka/${id}/${qr}`);
    }

}
