import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISostojba } from '../../models/fiskalna-kasa/sostojba';
import { IArtiklVoProdavnica } from '../../models/fiskalna-kasa/sostojba';
import { IKasa } from '../../models/fiskalna-kasa/sostojba';
import { ArtiklPostModel } from '../../models/fiskalna-kasa/artikl';
import { ZatvaranjeKasaPostModel } from '../../models/fiskalna-kasa/zatvaranje-kasa';
import { IProverkaNaArtikl } from '../../models/fiskalna-kasa/proverka-na-artikl';

@Injectable()
export class SostojbaService {

    collator: any;
    sostojba: ISostojba;

    constructor(private http: HttpClient) {
        this.collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    }

    // SOSTOJBA

    getSostojba(): Observable<ISostojba> { return this.http.get<ISostojba>(`/KasaApi/Sostojba/GetSostojba`); }

    refreshArtikli(): Observable<IArtiklVoProdavnica[]> {
        return this.http.get<IArtiklVoProdavnica[]>(`/KasaApi/Sostojba/RefreshArtikli`);
    }

    refreshKasa(): Observable<IKasa> { return this.http.get<IKasa>(`/KasaApi/Sostojba/RefreshKasa`); }

    postArtikl(model: ArtiklPostModel): Observable<IArtiklVoProdavnica> {
        return this.http.post<IArtiklVoProdavnica>(`/KasaApi/Sostojba/PostArtikl`, model);
    }

    getProverka(barkod: string): Observable<IProverkaNaArtikl> {
        return this.http.get<IProverkaNaArtikl>(`/KasaApi/Proverka/Get/${barkod}`);
    }

    // SREDUVANJE DEN

    zatvoriKasaInfo(model: ZatvaranjeKasaPostModel) {
        return this.http.post(`/KasaApi/Sostojba/ZatvoriKasaInfo`, model);
    }

    srediSostojba(iznos: number, kolicina: number, vid: number) {
        if (vid === 0) {
            this.sostojba.kasa.voGotovo += iznos;
        } else {
            this.sostojba.kasa.soKarticka += iznos;
        }
        this.sostojba.kasa.parcinjaDenes += kolicina;
        this.sostojba.kasa.parcinjaMesec += kolicina;
    }

}
