import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IKasier } from '../../models/fiskalna-kasa/evidencija-na-vreme';
import { IEvidencija } from '../../models/fiskalna-kasa/evidencija-na-vreme';

@Injectable()
export class EvidencijaNaVremeService {

    constructor(private http: HttpClient) {}

    getKasier(qr: string): Observable<IKasier> {
        return this.http.get<IKasier>(`/KasaApi/EvidencijaNaVreme/GetKasier/${qr}`);
    }

    postNastan(qr: string, vid: number): Observable<IEvidencija> {
        return this.http.get<IEvidencija>(`/KasaApi/EvidencijaNaVreme/PostNastan/${qr}/${vid}`);
    }
}
