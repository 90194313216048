import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISmetka, IStavka, StavkaPostModel } from '../../models/fiskalna-kasa/smetka';
import { IEncodedResult } from '../../models/shared/encoded-result';
import { NaplataPostModel } from '../../models/fiskalna-kasa/smetka';
import { TrkaloPostModel } from '../../kasa/dialogs/trkalo-dialog/trkalo-dialog.component';

@Injectable()
export class SmetkaService {

    constructor(private http: HttpClient) {}

    getSmetki(id: number): Observable<ISmetka[]> { return this.http.get<ISmetka[]>(`/KasaApi/Smetka/GetSmetki/${id}`); }

    getSmetka(id: string): Observable<ISmetka> { return this.http.get<ISmetka>(`/KasaApi/Smetka/GetSmetka/${id}`); }

    getPromocija(id: number): Observable<ISmetka> { return this.http.get<ISmetka>(`/KasaApi/Smetka/Popust/${id}`); }

    postKasier(id: number, qr: string): Observable<ISmetka> {
        return this.http.get<ISmetka>(`/KasaApi/Smetka/PostKasier/${id}/${qr}`);
    }

    postSmetka(kasaId: number, prodavacId: number): Observable<ISmetka> {
        return this.http.get<ISmetka>(`/KasaApi/Smetka/PostSmetka/${kasaId}/${prodavacId}`);
    }

    getStornaSmetka(smetkaId: number, smetkaZaStorniranjeId: number, znak: number): Observable<ISmetka> {
        return this.http.get<ISmetka>(`/KasaApi/Smetka/GetStornaSmetka/${smetkaId}/${smetkaZaStorniranjeId}/${znak}`);
    }

    deleteSmetka(id: number) { return this.http.get(`/KasaApi/Smetka/DeleteSmetka/${id}`); }

    postStavka(model: StavkaPostModel): Observable<IStavka> {
        return this.http.post<IStavka>(`/KasaApi/Smetka/PostStavka`, model);
    }

    editStavka(model: StavkaPostModel): Observable<IStavka> {
        return this.http.post<IStavka>(`/KasaApi/Smetka/EditStavka`, model);
    }

    deleteStavka(id: number) { return this.http.get(`/KasaApi/Smetka/DeleteStavka/${id}`); }

    zatvoriSmetka(id: number): Observable<ISmetka> {
        return this.http.get<ISmetka>(`/KasaApi/Smetka/ZatvoriSmetka/${id}`);
    }

    naplati(model: NaplataPostModel): Observable<IEncodedResult> {
        return this.http.post<IEncodedResult>(`/KasaApi/Smetka/Naplati`, model);
    }

    povtornoNaplati(id: number): Observable<IEncodedResult> {
        return this.http.get<IEncodedResult>(`/KasaApi/Smetka/PovtornoNaplati/${id}`);
    }

    postGiftKarticka(id: number, sifra: string): Observable<IEncodedResult> {
        return this.http.get<IEncodedResult>(`/KasaApi/Smetka/PostGiftKarticka/${id}/${sifra}`);
    }

    clenskaKarta(id: number, qr: string): Observable<ISmetka> {
        return this.http.get<ISmetka>(`/KasaApi/Smetka/ClenskaKarta/${id}/${qr}`);
    }

    trkalo(model: TrkaloPostModel): Observable<ISmetka> {
        return this.http.post<ISmetka>(`/KasaApi/Smetka/Trkalo`, model);
    }
}
