import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OverlayContainer } from '@angular/cdk/overlay';
import { JwtHelperService } from '@auth0/angular-jwt';
import { shareReplay, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { IJwtTokenStructure } from '../../models/jwt-token-structure';
import { IKorisnik } from '../../models/admin/korisnik';
import { KorisnikPostModel } from '../../models/admin/korisnik';
import { KorisnikPasswordPostModel } from '../../models/admin/korisnik';
import { SostojbaService } from '../fiskalna-kasa/sostojba.service';

@Injectable()
export class AccountService {

    loggedIn = false;
    phoneDisplay = false;
    decodedToken: IJwtTokenStructure;
    korisnik: IKorisnik;
    activeTheme = 'main-light-theme';

    constructor(public http: HttpClient,
        public sostojbaService: SostojbaService,
        public overlayContainer: OverlayContainer,
        public jwtHelper: JwtHelperService) {
        this.isLoggedIn();
    }

    private setSession(authResult) {
        const expiresAt = moment().add(authResult.expires_in, 'second');
        localStorage.setItem('auth_token', authResult.auth_token);
        localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
        this.loggedIn = true;
        this.setDecodedToken();
    }

    private setDecodedToken() {
        if (this.loggedIn) {
            this.decodedToken = this.jwtHelper.decodeToken(localStorage.getItem('auth_token'));
        } else {
            this.decodedToken = null;
            localStorage.removeItem('auth_token');
            localStorage.removeItem('expires_at');
        }
    }

    setTheme() {
        if (this.overlayContainer.getContainerElement().classList.contains('main-light-theme')) {
            this.overlayContainer.getContainerElement().classList.remove('main-light-theme');
        }
        if (this.overlayContainer.getContainerElement().classList.contains('main-dark-theme')) {
            this.overlayContainer.getContainerElement().classList.remove('main-dark-theme');
        }
        this.activeTheme = this.korisnik
                           ? (this.korisnik.theme === 0
                                  ? 'main-light-theme'
                                  : 'main-dark-theme')
                           : 'main-light-theme';
        this.overlayContainer.getContainerElement().classList.add(this.activeTheme);
    }

    login(email: string, password: string) {
        return this.http.post('/HomeApi/Account/Login', { email: email, password: password })
            .pipe(
                tap(result => this.setSession(result)),
                shareReplay());
    }

    logout() {
        this.loggedIn = false;
        this.korisnik = null;
        this.setDecodedToken();
        this.setTheme();
    }

    isLoggedIn() {
        this.loggedIn = moment().isBefore(this.getExpiration());
        this.setDecodedToken();
        this.setTheme();
        if (this.loggedIn && !this.korisnik) {
            this.getKorisnik().subscribe(result => {
                this.korisnik = result;
                this.setTheme();
                this.sostojbaService.getSostojba().subscribe(res => { this.sostojbaService.sostojba = res; });
            });
        }
        return this.loggedIn;
    }

    isLoggedOut() { return !this.isLoggedIn(); }

    getExpiration() {
        const expiration = localStorage.getItem('expires_at');
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    getKorisnik(): Observable<IKorisnik> { return this.http.get<IKorisnik>(`/HomeApi/Account/GetKorisnik`); }

    editKorisnik(model: KorisnikPostModel): Observable<IKorisnik> {
        return this.http.post<IKorisnik>(`/HomeApi/Account/EditKorisnik`, model);
    }

    changeKorisnikPassword(model: KorisnikPasswordPostModel) {
        return this.http.post(`/HomeApi/Account/ChangePassword`, model);
    }

}
