import { SostojbaService } from './sostojba.service';
import { SmetkaService } from './smetka.service';
import { KomandiService } from './komandi.service';
import { EvidencijaNaVremeService } from './evidencija-na-vreme.service';
import { GiftKartickiService } from './gift-karticka.service';

export const FISKALNA_KASA_SERVICES = [
    SostojbaService,
    SmetkaService,
    KomandiService,
    EvidencijaNaVremeService,
    GiftKartickiService
];
