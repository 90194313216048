import { AccountService } from './account.service';
import { MessageDialogService } from './message-dialog.service';
import { LoggedInGuard } from './logged-in.guard';
import { ZebraService } from './zebra.service';
import { SoundService } from './sound.service';

export const SHARED_SERVICE_DECLARATIONS: any[] = [
    LoggedInGuard,
    AccountService,
    MessageDialogService,
    ZebraService,
    SoundService
];
