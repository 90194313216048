// ANGULAR
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
const routes: Routes = [
    {
        path: '',
        redirectTo: 'Home',
        pathMatch: 'full'
    },
    {
        path: 'Home',
        loadChildren: () => import('../home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'Kasa',
        loadChildren: () => import('../kasa/kasa.module').then(m => m.KasaModule)
    }
];

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule {
}
