import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerComponent } from '../../dialogs/spinner/spinner.component';
import { MessageDialogComponent } from '../../dialogs/message-dialog/message-dialog.component';

@Injectable()
export class MessageDialogService {

    private spinner = [] as MatDialogRef<SpinnerComponent>[];

    constructor(private dialog: MatDialog) {}

    showSpinner() {
        this.spinner.push(this.dialog.open(SpinnerComponent, { disableClose: true }));
    }

    hideSpinner() {
        if (this.spinner.length) {
            this.spinner[0].close();
            this.spinner.splice(0, 1);
        }
    }

    showMessage(message: string, confirm: boolean = false): Observable<boolean> {
        return this.dialog
            .open(MessageDialogComponent, { disableClose: true, data: { message: message, confirm: confirm } })
            .afterClosed();
    }

}
