import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PeriodicenIzvestajPostModel } from '../../models/fiskalna-kasa/komandi';
import { SluzbeniPariPostModel } from '../../models/fiskalna-kasa/komandi';
import { IEncodedResult } from '../../models/shared/encoded-result';

@Injectable()
export class KomandiService {

    constructor(private http: HttpClient) {}

    // FISKALNI KOMANDI

    zatvoriKasa(): Observable<IEncodedResult> { return this.http.get<IEncodedResult>(`/KasaApi/Komandi/ZatvoriKasa`); }

    dnevenIzvestaj(): Observable<IEncodedResult> {
        return this.http.get<IEncodedResult>(`/KasaApi/Komandi/DnevenIzvestaj`);
    }

    periodicenIzvestaj(model: PeriodicenIzvestajPostModel): Observable<IEncodedResult> {
        return this.http.post<IEncodedResult>(`/KasaApi/Komandi/PeriodicenIzvestaj`, model);
    }

    sluzbeniPari(model: SluzbeniPariPostModel): Observable<IEncodedResult> {
        return this.http.post<IEncodedResult>(`/KasaApi/Komandi/SluzbeniPari`, model);
    }

    // FISCAL PRINT

    generateLink(encodedResult: IEncodedResult) { return `fiscalprint://${encodedResult.data}/`; }

    print(encodedResult: IEncodedResult) {
        const data = this.generateLink(encodedResult);
        const fileTag = document.createElement('a');
        fileTag.href = data;
        fileTag.click();
    }
}
